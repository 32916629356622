import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { FiArrowUpRight } from "react-icons/fi";
import VerticalAccordion from "./AchievementsCards";
import styles from "./../bubble.module.css";
import whatbytesImg from "./../Images/whatbytes.png"
import amberstudentImg from "./../Images/amberstudent.png"
import idsImg from "./../Images/idsImage.png"

export const TextParallaxContentExample = () => {
    return (
    <div className="bg-white">
        <TextParallaxContent
        imgUrl="https://images.pexels.com/photos/4078342/pexels-photo-4078342.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        subheading=""
        heading="My Work Experience"
      >
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl="https://static.startuptalky.com/2023/06/amber-red-logo-StartupTalky.jpg"
        subheading="June 2024 - Present"
        heading="amber"
      >
        <ExampleContent />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl={whatbytesImg}
        subheading="Feb 2024 - June 2024"
        heading="WhatBytes"
      >
        <ExampleContent2 />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl="https://media.licdn.com/dms/image/v2/D4D12AQFCmuVMhtsw8g/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1724065473021?e=2147483647&v=beta&t=TQObj3GRRrn6xzHoTY0AYrw8L0nkbOtIXydiaERIi9A"
        subheading="July 2023 – Sept. 2023"
        heading="Space Applications Centre, ISRO"
      >
        <ExampleContent3 />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl={idsImg}
        subheading="Feb 2023 - Apr 2023"
        heading="IDS (Information Data Systems)"
      >
        <ExampleContent4 />
      </TextParallaxContent>
        
        {/* <div className="bg-bgachievements h-screen flex flex-col justify-center items-center"> 
            <h2 className="mt-12"><BubbleText/></h2>
            <div className="-mt-24"><VerticalAccordion/> </div>
            
        </div> */}
    </div>
  );
};

const IMG_PADDING = 12;

const TextParallaxContent = ({ imgUrl, subheading, heading, children }) => {
  return (
    <div
      style={{
        paddingLeft: IMG_PADDING,
        paddingRight: IMG_PADDING,
      }}
    >
      <div className="relative h-[150vh]">
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy heading={heading} subheading={subheading} />
      </div>
      {children}
    </div>
  );
};

const StickyImage = ({ imgUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        scale,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden rounded-3xl"
    >
      <motion.div
        className="absolute inset-0 bg-neutral-950/70"
        style={{
          opacity,
        }}
      />
    </motion.div>
  );
};

const OverlayCopy = ({ subheading, heading }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <p className="mb-2 text-center text-xl md:mb-4 md:text-3xl">
        {subheading}
      </p>
      <p className="text-center text-4xl font-bold md:text-7xl">{heading}</p>
    </motion.div>
  );
};

const ExampleContent = () => {
    
    const handleAmberClick = (event) => {
        event.preventDefault(); // Prevents any default behavior if necessary
        window.open("https://amberstudent.com", "_blank", "noopener,noreferrer");
      };
    return(
  <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
    <h2 className="col-span-1 text-3xl font-bold md:col-span-4">
      Associate Software Development Engineer (ASDE) @amberstudent
    </h2>
    <div className="col-span-1 md:col-span-8">
      <p className="mb-4 text-xl text-neutral-600 md:text-2xl">
      As an ASDE at Amber, I have primarily focused on frontend development using React. I recently updated our
      codebase to align with the ”react-cool-starter” design guide, enhancing SEO performance and improving key metrics
      like FCP and LCP for a better user experience. 
      </p>
      <p className="mb-8 text-xl text-neutral-600 md:text-2xl">
      Additionally, I have been working with WeChat Devtools, solely
      developing a Mini-Program WeChat App for our Chinese users. I have also worked on optimizing a Google Ranking Sync System and
      contributed to a property comparison system to assist the SEO team in optimizing performance.
      </p>
      <button className="w-full rounded bg-neutral-900 px-9 py-4 text-xl text-white transition-colors hover:bg-neutral-700 md:w-fit" onClick={handleAmberClick}>
        Visit Amber <FiArrowUpRight className="inline" />
      </button>
    </div>
  </div>
);}

const ExampleContent2 = () => 
    
    (
  <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
    <h2 className="col-span-1 text-3xl font-bold md:col-span-4">
      Design Engineering Intern @WhatBytes
    </h2>
    <div className="col-span-1 md:col-span-8">
      <p className="mb-8 text-xl text-neutral-600 md:text-2xl">
      Worked as a Design Engineer at WhatBytes, a service-based startup, where I designed and delivered user-centric web experiences for clients globally. Led projects like <u>flamme.app</u>, <u>megafuse.tech</u>, and various personal portfolios, collaborating closely with clients to bring their visions to life through seamless UI/UX design.
      </p>
    
    </div>
  </div>
)

const ExampleContent3 = () => {
    
    const handleISROClick = (event) => {
        event.preventDefault(); // Prevents any default behavior if necessary
        window.open("https://www.linkedin.com/posts/notyashgrover_as-2023-draws-to-a-close-im-thrilled-to-activity-7147628470125174784-WFLy/?utm_source=share&utm_medium=member_desktop", "_blank", "noopener,noreferrer");
      };
    return(
  <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
    <h2 className="col-span-1 text-3xl font-bold md:col-span-4">
     Software Development Intern @SAC, ISRO
    </h2>
    <div className="col-span-1 md:col-span-8">
      <p className="mb-4 text-xl text-neutral-600 md:text-2xl">
      Contributed to a pivotal project, the ’ISO:9001 2015 Quality Management System, SAC’ an internal auditing system
critical for SAC’s pursuit of excellence. Played a key role as a full-stack developer in developing three fundamental
modules of the software (Admin, Auditor and Auditee). 

      </p>
      <p className="mb-8 text-xl text-neutral-600 md:text-2xl">
      Technologies Used : Java (MVC), NetBeans, SQL, SAC’s Internal Backend Management (COWAA).
      </p>
      <button className="w-full rounded bg-neutral-900 px-9 py-4 text-xl text-white transition-colors hover:bg-neutral-700 md:w-fit" onClick={handleISROClick}>
        View More <FiArrowUpRight className="inline" />
      </button>
    </div>
  </div>
);}

const ExampleContent4 = () => {
    
    return(
  <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
    <h2 className="col-span-1 text-3xl font-bold md:col-span-4">
      UX Intern @IDS (Information Data Systems)
    </h2>
    <div className="col-span-1 md:col-span-8">
      <p className="mb-4 text-xl text-neutral-600 md:text-2xl">
      Worked as a UX Intern, where I had the opportunity to collaborate with industry professionals and gain hands-on experience in user research and interface design. Contributed to projects like the BBN Dashboard (Bharat Blockchain Network) and Polyversity, a government-backed initiative launched by the Minister of Education. 
      </p>
    </div>
  </div>
);}

const BubbleText = () => {
    return (
      <h2 className="text-center text-3xl lg:text-5xl cursor-default font-thin text-white">
        {"A few more things that might interest you 👀".split("").map((child, idx) => (
          <span className={styles.hoverText} key={idx}>
            {child}
          </span>
        ))}
      </h2>
    );
  };
  