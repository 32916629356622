import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {FiDownload} from 'react-icons/fi';
import skill1 from './../Images/2.png'
import skill2 from './../Images/3.png'
import skill3 from './../Images/4.png'
import skill4 from './../Images/5.png'
import skill5 from './../Images/6.png'
import skill6 from './../Images/7.png'
import skill7 from './../Images/8.png'
import skill8 from './../Images/9.png'
import skill9 from './../Images/10.png'
import skill10 from './../Images/11.png'
import skill11 from './../Images/12.png'
import skill12 from './../Images/13.png'
import skill13 from './../Images/14.png'
import skill14 from './../Images/15.png'
import skill15 from './../Images/16.png'
import skill16 from './../Images/17.png'
import { AnimatePresence} from "framer-motion";
import { FiLinkedin,FiGithub,FiYoutube,FiMail } from "react-icons/fi";

const ShuffleHero = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleModalClick = () =>{
    setIsOpen(true)
  }
  return (
    <section className="w-full px-8 py-12 grid grid-cols-1 md:grid-cols-2 items-center gap-8 max-w-6xl mx-auto">
      <div>
      
        <span className="block mb-4 text-xs md:text-sm text-[#0064fe] font-medium">
          Namaste🙏🏻, Yash this side
        </span>
        <h3 className="text-4xl text-white md:text-6xl font-semibold">
          I'm a Developer and Designer
        </h3>
        <p className="text-base md:text-lg text-slate-200 my-4 md:my-6">
         and a tech enthusiast having a keen interest in developing Applications and simplifying User Experience.
        </p>
        <div className="flex items-center">
        <a href="https://drive.google.com/file/d/1j7nXDlo_7grILE-iceJcU3s-VX7m9t-W/view?usp=sharing" target="_blank"> 
        <button className="hover:scale-105 ease-in-out duration-300 flex items-center justify-center bg-[#0064fe] text-white font-medium py-2 px-4 rounded transition-all hover:bg-[#0050cb] active:scale-95">
           Resume <FiDownload className="m-2"/>
        </button>
        </a>
        <button onClick={handleModalClick} className="mx-2 hover:scale-105 ease-in-out duration-300 flex items-center justify-center bg-white bg-opacity-5  text-[#0064fe]  underline hover:no-underline font-medium py-3 px-4 rounded transition-all hover:bg-opacity-100 active:scale-95">
           Get in touch
        </button>
        </div>
      </div>
      <span data-tooltip-id="my-tooltip-1"><ShuffleGrid /></span>
      <SpringModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <ReactTooltip
        id="my-tooltip-1"
        place="left-end"
        content="My Key Skills"
      />
    </section>
  );
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const squareData = [
  {
    id: 1,
    src: skill1,
    linktotech:"https://tailwindcss.com/",
    name: "Tailwind"
  },
  { 
    id: 2,
    src: skill2,
    linktotech:"https://www.framer.com/motion/",
    name: "F.M."
  },
  {
    id: 3,
    src: skill3,
    linktotech:"https://react.dev/",
    name: "React.js"
  },
  {
    id: 4,
    src: skill4,
    linktotech:"https://redux.js.org/",
    name: "Redux"
  },
  {
    id: 5,
    src: skill5,
    linktotech:"https://firebase.google.com/",
    name: "Firebase"
  },
  {
    id: 6,
    src: skill6,
    linktotech:"https://nodejs.org/en",
    name: "nodejs"
  },
  {
    id: 7,
    src: skill7,
    linktotech:"https://github.com/theyashgrover",
    name: "GitHub"
  },
  {
    id: 8,
    src:skill8,
    linktotech:"https://git-scm.com/",
    name: "Git"
  },
  {
    id: 9,
    src: skill9,
    linktotech:"https://developer.android.com/courses/android-basics-compose/",
    name: "Android-Dev"
  },
  {
    id: 10,
    src: skill10,
    linktotech:"https://kotlinlang.org/",
    name: "Kotlin"
  },
  {
    id: 11,
    src: skill11,
    linktotech:"https://www.figma.com/",
    name: "Figma"
  },
  {
    id: 12,
    src: skill12,
    linktotech:"https://spline.design/",
    name: "Spline"
  },
  {
    id: 13,
    src:skill13,
    linktotech:"https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    name: "JavaScript"
  },
  {
    id: 14,
    src: skill14,
    linktotech:"https://getbootstrap.com/",
    name: "Bootstrap"
  },
  {
    id: 15,
    src: skill15,
    linktotech:"https://www.coursera.org/learn/ux-design-fundamentals",
    name: "UX"
  },
  {
    id: 16,
    src: skill16,
    linktotech:"https://www.mysql.com/",
    name: "MySQL"
  },
];

const generateSquares = () => {
  return shuffle(squareData).map((sq) => (
    <motion.div
      key={sq.id}
      layout
      transition={{ duration: 1.5, type: "spring" }}
      className="w-full h-full shadow-xs shadow-slate-50 hover:grayscale-[60%] cursor-pointer"
      style={{
        backgroundImage: `url(${sq.src})`,
        backgroundSize: "cover",
      }}
    ><a href={sq.linktotech} target="_blank" className="hidden lg:block lg:text-[#121212] lg:text-xs"> About {sq.name} </a></motion.div>
  ));
};

const ShuffleGrid = () => {
  const timeoutRef = useRef(null);
  const [squares, setSquares] = useState(generateSquares());

  useEffect(() => {
    shuffleSquares();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  const shuffleSquares = () => {
    setSquares(generateSquares());

    timeoutRef.current = setTimeout(shuffleSquares, 3000);
  };

  return (
    <div className="grid grid-cols-4 grid-rows-4 w-[250px] h-[250px] lg:h-[450px] lg:w-[450px] gap-1 hover:scale-105 ease-in-out duration-300 ">
      {squares.map((sq) => sq)}
    </div>
  );
};

export default ShuffleHero;

 
const SpringModal = ({ isOpen, setIsOpen }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsOpen(false)}
          className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
        >
          <motion.div
            initial={{ scale: 0, rotate: "12.5deg" }}
            animate={{ scale: 1, rotate: "0deg" }}
            exit={{ scale: 0, rotate: "0deg" }}
            onClick={(e) => e.stopPropagation()}
            className="bg-gradient-to-br from-[#0064fe] to-[#0050cb] text-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
          >
            <FiMail className="text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" />
            <div className="relative z-10">
              <div className="bg-white w-16 h-16 mb-2 rounded-full text-3xl text-[#0064fe] grid place-items-center mx-auto">
                <FiMail />
              </div>
              <h3 className="text-3xl font-bold text-center mb-2">
                Hey there!
              </h3>
              <p className="text-center mb-6">
              You can browse more in this portfolio through the menu tab available on the top right.
              Thanks for wanting to connect,
              You can reach me through mail or my social handles:
                <span className='flex px-4 md:px-0 py-3 pr-3 justify-evenly'>
                  <a href='https://www.linkedin.com/in/notyashgrover/' target='__blank'><FiLinkedin className='cursor-pointer hover:animate-bounce-slow ease-in-out w-6 h-6 hover:text-slate-400 ml-10 md:ml-0 mx-2 md:mx-0'/></a> 
                  <a href='https://github.com/theyashgrover' target='__blank'><FiGithub className='cursor-pointer hover:animate-bounce-slow ease-in-out w-6 h-6 hover:text-[#1e1e1e] mx-2 md:mx-0' /></a>
                  <a href='https://www.youtube.com/channel/UCxVJ6h5BfSNL3p_HLl3HBrw' target='__blank'><FiYoutube className='cursor-pointer hover:animate-bounce-slow w-6 h-6 ease-in-out hover:text-red-700 mx-2 md:mx-0'/></a>
              </span>
              </p>
              <div className="flex gap-2">
                <button
                  onClick={() => setIsOpen(false)}
                  className="bg-transparent hover:bg-white/10 transition-colors text-white font-semibold w-full py-2 rounded"
                >
                  Nah, go back
                </button>
                <button
                  onClick={(e) => {
                    setIsOpen(false); // Close the modal
                    window.location.href = "mailto:yashgrover1511@gmail.com?subject=Reaching%20through%20your%20portfolio&body=Hi%20Yash,%20I'm...";
                    e.preventDefault(); // Prevent default behavior of anchor tag
                }}
                  className="bg-white hover:opacity-90 transition-opacity text-[#0064fe] font-semibold w-full py-2 rounded"
                >
                  Mail Me!
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
